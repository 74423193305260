class Error {
  constructor(message) {
    this.message = message
    this.name = 'Error'
    this.stack = undefined
  }
}

export class GraphicTradeError extends Error {
  constructor(message) {
    super(message)
    this.name = 'GraphicTradeError'
  }
}
