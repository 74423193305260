var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trading-balance-panel"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._m(0), _c('BalanceGraphic')], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wallet-box"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('h2', {
    staticClass: "title-h2"
  }, [_vm._v("Amount of capital")])])]);

}]

export { render, staticRenderFns }