var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trade-page"
  }, [_c('div', {
    staticClass: "general-box"
  }, [_c('div', {
    staticClass: "left-wrap-col"
  }, [_c('TradeGraphicPanel'), _c('div', {
    staticClass: "body"
  }, [_c('Deals')], 1)], 1), _c('div', {
    staticClass: "right-wrap-col"
  }, [_c('BalanceHistoryPanel'), _c('Wallet'), _c('TradePairs'), _c('BotTerminal')], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }