<template>
  <div class="trade-block">
    <div class="trade-chart-panel">
      <template v-if="getterSelectedPair">
        <div class="header">
          <div class="trade-onheader">
            <div class="onheader-block">
              <div class="onheader-title">
                24h Change
              </div>
              <div
                class="onheader-persents red"
                :class="[
                  getterSelectedPairDayStats?.priceChangePercent?.includes('-')
                    ? 'red'
                    : 'green',
                ]"
              >
                {{ getterSelectedPairDayStats?.priceChangePercent }}%
              </div>
              <div class="onheader-main">
                ${{ getterSelectedPairDayStats?.priceChange | tradeCurrency }}
              </div>
            </div>
            <div class="onheader-block">
              <div class="onheader-title">
                24h High
              </div>
              <div class="onheader-persents"></div>
              <div class="onheader-main">
                ${{ getterSelectedPairDayStats?.highPrice | tradeCurrency }}
              </div>
            </div>
            <div class="onheader-block">
              <div class="onheader-title">
                24h Low
              </div>
              <div class="onheader-persents"></div>
              <div class="onheader-main">
                ${{ getterSelectedPairDayStats?.lowPrice | tradeCurrency }}
              </div>
            </div>
            <div class="onheader-block">
              <div class="onheader-title">
                24h Volume (BTC)
              </div>
              <!-- <div class="onheader-persents green">
                -4.11%
              </div> -->
              <div class="onheader-main">
                {{ getterSelectedPairDayStats?.volume | volume }}
              </div>
            </div>
          </div>

          <!-- <TradePairs /> -->

          <div class="trade-header">
            <div class="chain">
              <div class="chain__info-box">
                <div class="pair-name">
                  <cryptoicon
                    :symbol="getterSelectedPair.pair | getCurrencyFromPair"
                    size="48"
                  />{{ getterSelectedPair.pair }}
                </div>
                <div class="pair-price">
                  ${{ getterSelectedPair.price | tradeCurrency }}
                </div>
              </div>
            </div>
            <div class="status">
              <div class="options__box">
                <div class="total-balance" v-if="balanceStats">
                  Trading Balance: {{ balanceStats.now | currency }}
                </div>
                <div class="options">
                  <div
                    class="no-wrap"
                    :class="showMarkers ? 'item active' : 'item'"
                    @click="showMarkers = !showMarkers"
                    :hidden="disabledMarkers"
                  >
                    Markers: {{ showMarkers ? 'on' : 'off' }}
                  </div>

                  <div class="item item-time">
                    <span>Time: </span>
                    <select @change="onChangeSelectTime($event)">
                      <option
                        v-for="(opt, key) in times"
                        :key="key"
                        :value="opt"
                        >{{ opt }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="body">
          <div class="graphic-col" ref="graphicCol">
            <trading-vue
              :style="graphStyles"
              :data="chart"
              ref="chart"
              :width="widthChart"
              :titleTxt="''"
              colorScale="#ECECEC"
              colorBack="transparent"
              colorGrid="#ECECEC"
              colorText="#989DBE"
              colorCandleUp="#65D072"
              colorCandleDW="#D32D2F"
              :overlays="overlays"
              :timezone="timezoneGMT"
            ></trading-vue>
          </div>
        </div>
      </template>
      <template v-else>
        <!-- <div class="body"> -->
        <UiPreloader />
        <!-- </div> -->
      </template>
    </div>
  </div>
</template>

<script>
import TradingVue from 'trading-vue-js'
import TradeGraphMixin from './trade-graph'
import TradePairs from './TradePairs'
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    TradingVue,
    TradePairs,
  },

  mixins: [TradeGraphMixin],

  data: () => ({
    dropPeriodSelect: false,
  }),

  methods: {
    hideDropPeriodSelect() {
      this.dropPeriodSelect = false
    },

    onChangeSelectTime(event) {
      this.onUpdatePeriodCandles(event.target.value)
    },
  },

  computed: {
    ...mapState('Finance', ['balanceStats', 'favoritePeriodTimes']),
    ...mapGetters('Finance', ['getterSelectedPairDayStats']),
  },
}
</script>
