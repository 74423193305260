var render = function render(){
  var _vm$getterSelectedPai, _vm$getterSelectedPai2, _vm$getterSelectedPai3, _vm$getterSelectedPai4, _vm$getterSelectedPai5, _vm$getterSelectedPai6, _vm$getterSelectedPai7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trade-block"
  }, [_c('div', {
    staticClass: "trade-chart-panel"
  }, [_vm.getterSelectedPair ? [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "trade-onheader"
  }, [_c('div', {
    staticClass: "onheader-block"
  }, [_c('div', {
    staticClass: "onheader-title"
  }, [_vm._v(" 24h Change ")]), _c('div', {
    staticClass: "onheader-persents red",
    class: [(_vm$getterSelectedPai = _vm.getterSelectedPairDayStats) !== null && _vm$getterSelectedPai !== void 0 && (_vm$getterSelectedPai2 = _vm$getterSelectedPai.priceChangePercent) !== null && _vm$getterSelectedPai2 !== void 0 && _vm$getterSelectedPai2.includes('-') ? 'red' : 'green']
  }, [_vm._v(" " + _vm._s((_vm$getterSelectedPai3 = _vm.getterSelectedPairDayStats) === null || _vm$getterSelectedPai3 === void 0 ? void 0 : _vm$getterSelectedPai3.priceChangePercent) + "% ")]), _c('div', {
    staticClass: "onheader-main"
  }, [_vm._v(" $" + _vm._s(_vm._f("tradeCurrency")((_vm$getterSelectedPai4 = _vm.getterSelectedPairDayStats) === null || _vm$getterSelectedPai4 === void 0 ? void 0 : _vm$getterSelectedPai4.priceChange)) + " ")])]), _c('div', {
    staticClass: "onheader-block"
  }, [_c('div', {
    staticClass: "onheader-title"
  }, [_vm._v(" 24h High ")]), _c('div', {
    staticClass: "onheader-persents"
  }), _c('div', {
    staticClass: "onheader-main"
  }, [_vm._v(" $" + _vm._s(_vm._f("tradeCurrency")((_vm$getterSelectedPai5 = _vm.getterSelectedPairDayStats) === null || _vm$getterSelectedPai5 === void 0 ? void 0 : _vm$getterSelectedPai5.highPrice)) + " ")])]), _c('div', {
    staticClass: "onheader-block"
  }, [_c('div', {
    staticClass: "onheader-title"
  }, [_vm._v(" 24h Low ")]), _c('div', {
    staticClass: "onheader-persents"
  }), _c('div', {
    staticClass: "onheader-main"
  }, [_vm._v(" $" + _vm._s(_vm._f("tradeCurrency")((_vm$getterSelectedPai6 = _vm.getterSelectedPairDayStats) === null || _vm$getterSelectedPai6 === void 0 ? void 0 : _vm$getterSelectedPai6.lowPrice)) + " ")])]), _c('div', {
    staticClass: "onheader-block"
  }, [_c('div', {
    staticClass: "onheader-title"
  }, [_vm._v(" 24h Volume (BTC) ")]), _c('div', {
    staticClass: "onheader-main"
  }, [_vm._v(" " + _vm._s(_vm._f("volume")((_vm$getterSelectedPai7 = _vm.getterSelectedPairDayStats) === null || _vm$getterSelectedPai7 === void 0 ? void 0 : _vm$getterSelectedPai7.volume)) + " ")])])]), _c('div', {
    staticClass: "trade-header"
  }, [_c('div', {
    staticClass: "chain"
  }, [_c('div', {
    staticClass: "chain__info-box"
  }, [_c('div', {
    staticClass: "pair-name"
  }, [_c('cryptoicon', {
    attrs: {
      "symbol": _vm._f("getCurrencyFromPair")(_vm.getterSelectedPair.pair),
      "size": "48"
    }
  }), _vm._v(_vm._s(_vm.getterSelectedPair.pair) + " ")], 1), _c('div', {
    staticClass: "pair-price"
  }, [_vm._v(" $" + _vm._s(_vm._f("tradeCurrency")(_vm.getterSelectedPair.price)) + " ")])])]), _c('div', {
    staticClass: "status"
  }, [_c('div', {
    staticClass: "options__box"
  }, [_vm.balanceStats ? _c('div', {
    staticClass: "total-balance"
  }, [_vm._v(" Trading Balance: " + _vm._s(_vm._f("currency")(_vm.balanceStats.now)) + " ")]) : _vm._e(), _c('div', {
    staticClass: "options"
  }, [_c('div', {
    staticClass: "no-wrap",
    class: _vm.showMarkers ? 'item active' : 'item',
    attrs: {
      "hidden": _vm.disabledMarkers
    },
    on: {
      "click": function ($event) {
        _vm.showMarkers = !_vm.showMarkers;
      }
    }
  }, [_vm._v(" Markers: " + _vm._s(_vm.showMarkers ? 'on' : 'off') + " ")]), _c('div', {
    staticClass: "item item-time"
  }, [_c('span', [_vm._v("Time: ")]), _c('select', {
    on: {
      "change": function ($event) {
        return _vm.onChangeSelectTime($event);
      }
    }
  }, _vm._l(_vm.times, function (opt, key) {
    return _c('option', {
      key: key,
      domProps: {
        "value": opt
      }
    }, [_vm._v(_vm._s(opt))]);
  }), 0)])])])])])]), _c('div', {
    staticClass: "body"
  }, [_c('div', {
    ref: "graphicCol",
    staticClass: "graphic-col"
  }, [_c('trading-vue', {
    ref: "chart",
    style: _vm.graphStyles,
    attrs: {
      "data": _vm.chart,
      "width": _vm.widthChart,
      "titleTxt": '',
      "colorScale": "#ECECEC",
      "colorBack": "transparent",
      "colorGrid": "#ECECEC",
      "colorText": "#989DBE",
      "colorCandleUp": "#65D072",
      "colorCandleDW": "#D32D2F",
      "overlays": _vm.overlays,
      "timezone": _vm.timezoneGMT
    }
  })], 1)])] : [_c('UiPreloader')]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }