<template>
  <div class="bot-terminal-panel">
    <div class="header">
      <div class="header-box">
        <div class="title-h2">Trading Statistics Log</div>
      </div>
    </div>
    <div class="body">
      <div class="commands" ref="commandsRef">
        <div class="item" v-for="(item, i) in sortedListMessages" :key="i">
          <div class="time d-flex no-wrap">
            {{ item.createdAt | moment('DD-MM-yyyy') }}
            <div style="width: 15px;"></div>
            {{ item.createdAt | moment('HH:mm') }}
          </div>
          <div class="details">
            <!-- TODO: Fix replaces -->
            {{ $t(item.langKey, getArgs(item.langArgs))?.replace('Sell', '').replace('Buy', '') }}
            <ButtonRedGreen style="margin-left: 15px;" v-if="item.mark" :status="item.mark === 'buy'" :content="item.mark"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ButtonRedGreen from "../../components/ButtonRedGreen";

export default {
  components:{
    ButtonRedGreen
  },
  data: () => ({
    listMessages: [],
  }),

  mounted() {
    this.getBotTerminalMessages().then(list => {
      this.listMessages = list
      this.scrollToDown()
    })
  },

  computed: {
    sortedListMessages() {
      const sorted =  this.listMessages
        .slice()
        .sort((a, b) => this.$moment(a.createdAt) - this.$moment(b.createdAt))
        .map((el)=>{
          if(el.langKey.endsWith('buy')) return {...el, mark: 'buy'}
          if(el.langKey.endsWith('sell')) return {...el, mark: 'sell'}
          return el
        })
      return sorted;
    },
  },

  sockets: {
    'bot-terminal-message'(msg) {
      this.listMessages.push(msg)
      this.scrollToDown()
    },
  },

  methods: {
    ...mapActions('App', ['getBotTerminalMessages']),

    getArgs(json) {
      return json ? JSON.parse(json) : null
    },

    scrollToDown() {
      this.$nextTick(() => {
        this.$refs.commandsRef.scrollTop = this.$refs.commandsRef.scrollHeight
      })
    },
  },
}
</script>
