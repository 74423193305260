<template>
  <div class="trade-page">
    <!-- <div class="user-header__icons">
      <div class="notification">
        <img src="../../layouts/user/img/fx_bell.svg" alt="bell" />
      </div>
      <div class="person">
        <img src="../../layouts/user/img/fx_user.svg" alt="person" />
      </div>
    </div> -->
    <div class="general-box">
      <div class="left-wrap-col">
        <TradeGraphicPanel />
        <div class="body">
          <Deals />
        </div>
      </div>
      <div class="right-wrap-col">
        <BalanceHistoryPanel />
        <Wallet />
        <TradePairs />
        <BotTerminal />
      </div>
    </div>
  </div>
</template>

<script>
import Wallet from './Wallet.vue'
import TradeGraphicPanel from './TradeGraphicPanel.vue'
import BotTerminal from './BotTerminal.vue'
import Deals from './Deals'
import { mapState } from 'vuex'
import BalanceHistoryPanel from './BalanceHistoryPanel.vue'
import TradePairs from './TradePairs'

const DEF_VIEW_TF = '30m'

export default {
  components: {
    Wallet,
    TradeGraphicPanel,
    BotTerminal,
    BalanceHistoryPanel,
    Deals,
    TradePairs,
  },
}
</script>

<style lang="scss" src="./styles/src.scss"></style>
