var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    class: [_vm.status === false ? 'red' : 'green', _vm.content.endsWith('%') === true ? 'arrow' : '']
  }, [_vm._v(" " + _vm._s(_vm.content) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }