var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wallet-panel"
  }, [_c('div', {
    staticClass: "header-wrapper header-wrapper--desktop"
  }, [_vm._m(0), _c('div', {
    staticClass: "deals-header-block"
  }, [_c('div', {
    staticClass: "deals-pagination"
  }, [_vm.allMarkers.length > _vm.perPage ? _c('b-pagination', {
    attrs: {
      "total-rows": _vm.allMarkers.length,
      "per-page": _vm.perPage,
      "aria-controls": "paginated-table",
      "hide-goto-end-buttons": "",
      "align": "right",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  }) : _vm._e()], 1), _vm.percentDone ? _c('button', {
    staticClass: "deals-export"
  }, [_vm._v(" " + _vm._s(_vm.percentDone) + "% ")]) : _c('button', {
    staticClass: "deals-export",
    on: {
      "click": _vm.handleExportHistory
    }
  }, [_vm._v(" Export ")])])]), _c('div', {
    staticClass: "header-wrapper header-wrapper--mobile"
  }, [_vm._m(1), _c('div', {
    staticClass: "deals-header-block"
  }, [_vm.percentDone ? _c('button', {
    staticClass: "deals-export"
  }, [_vm._v(" " + _vm._s(_vm.percentDone) + "% ")]) : _c('button', {
    staticClass: "deals-export",
    on: {
      "click": _vm.handleExportHistory
    }
  }, [_vm._v(" Export ")])])]), _c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "wallet-information"
  }, [_c('table', {
    staticClass: "table-wallet"
  }, [_vm._m(2), _c('tbody', _vm._l(_vm.tableMarkersData, function (item, i) {
    return _c('tr', {
      key: i,
      class: {
        'buy-row': item.move > 0
      }
    }, [_c('td', [_vm._v(" " + _vm._s(_vm._f("moment")(new Date(item.openTime).toJSON(), 'DD-MM-yyyy')) + "     " + _vm._s(_vm._f("moment")(new Date(item.openTime).toJSON(), 'HH:mm')) + " ")]), _c('td', [_c('div', [_c('ButtonRedGreen', {
      attrs: {
        "status": item.move > 0,
        "content": item.move > 0 ? 'buy' : 'sell'
      }
    })], 1)]), _c('td', [_vm._v(" " + _vm._s(_vm._f("tradeCurrency")(item.size)) + " ")]), _c('td', {
      staticClass: "name"
    }, [_c('cryptoicon', {
      attrs: {
        "symbol": _vm._f("getCurrencyFromPair")(item.pair),
        "size": "22"
      }
    }), _c('span', {
      staticStyle: {
        "margin-left": "10px"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("nameToPair")(item.pair)) + " ")])], 1), _c('td', [_vm._v(" " + _vm._s(_vm._f("currency")(item.priceOpen)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm._f("moment")(new Date(item.closeTime).toJSON(), 'DD-MM-yyyy HH:mm')) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm._f("currency")(item.priceClose)) + " ")]), _c('td', [_c('div', [_c('ButtonRedGreen', {
      staticClass: "arrow",
      staticStyle: {
        "font-weight": "600"
      },
      attrs: {
        "status": item.profit > 0,
        "content": _vm._f("tradeCurrency")(item.profit)
      }
    })], 1)])]);
  }), 0)])])]), _c('div', {
    staticClass: "deals-pagination deals-pagination--bottom"
  }, [_vm.allMarkers.length > _vm.perPage ? _c('b-pagination', {
    attrs: {
      "total-rows": _vm.allMarkers.length,
      "per-page": _vm.perPage,
      "aria-controls": "paginated-table",
      "hide-goto-end-buttons": "",
      "align": "right",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  }) : _vm._e()], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('h2', {
    staticClass: "title-h2"
  }, [_vm._v("Deals")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('h2', {
    staticClass: "title-h2"
  }, [_vm._v("Deals")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('td', [_vm._v("Open time")]), _c('td', [_vm._v("Type")]), _c('td', [_vm._v("Size")]), _c('td', [_vm._v("Pair name")]), _c('td', [_vm._v("Open price")]), _c('td', [_vm._v("Close time")]), _c('td', [_vm._v("Close price")]), _c('td', [_vm._v("Bot profit")])])]);

}]

export { render, staticRenderFns }