<template>
  <div class="pairs-panel">
    <div class="pairs-panel-title">
      Pairs
    </div>
    <div class="pairs-panel-list">
      <table>
        <thead>
          <tr>
            <td>Name</td>
            <td>Price, $</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in zones"
            :key="i"
            :class="{
              tradable: item.tradable,
              active: item.pair == activePair,
            }"
            @click="handleClickOnPair(item, i)"
          >
            <td class="name">
              <cryptoicon :symbol="item.pair | getCurrencyFromPair" size="32" />
              {{ item.pair }}
            </td>
            <td class="price">${{ item.price | tradeCurrency }}</td>
          </tr>
        </tbody>
      </table>
      <!-- <div
            v-for="(item, i) in zones"
            :key="i"
            :class="{
              tradable: item.tradable,
              active: item.pair == activePair,
            }"
            @click="handleClickOnPair(item, i)"
          >
            {{ item.pair }}
          </div> -->
    </div>
    <!-- <div class="body">
      <div class="main-box">
        <div class="pairs-table-scroll">
          <div
            v-for="(item, i) in zones"
            :key="i"
            :class="{
              tradable: item.tradable,
              active: item.pair == activePair,
            }"
            @click="handleClickOnPair(item, i)"
          >
            {{ item.pair }}
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  data: () => ({
    search: '',
    sortBy: undefined,
    sortedTypes: {
      name: 1,
      price: 2,
    },
    activeItem: 0,
  }),

  computed: {
    ...mapState('Finance', [
      'futuresPrices',
      'favoritePairs',
      'tradablePairs',
      'selectedPairName',
    ]),

    activePair() {
      return this.selectedPairName.replace('USDT', '/USDT')
    },

    zones() {
      return this.futuresPrices
        .flatMap(e => {
          if (
            this.search.length > 0 &&
            !e.name.toLowerCase().includes(this.search.toLowerCase())
          )
            return []

          return [
            {
              ...e,
              tradable: this.tradablePairs.find(f => f.name == e.name),
            },
          ]
        })
        .sort((a, b) => b.tradable - a.tradable)
    },
  },

  created() {
    this.getTradablePairs()
  },

  methods: {
    ...mapActions('Finance', ['getTradablePairs']),

    ...mapMutations('Finance', [
      'TOGGLE_FAVORITE_PAIR',
      'UPDATE_SELECTED_PAIR',
    ]),

    toggleFavorite(name) {
      this.TOGGLE_FAVORITE_PAIR(name)
    },

    handleClickOnPair(item, i) {
      this.UPDATE_SELECTED_PAIR(item.name)
      this.activeItem = i
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },

    /*splitCoinName(fullCoinName){
      console.log(111)
      return this.fullCoinName.split('/')
    }*/
  },
}
</script>

<style scoped lang="scss">
.pairs-panel {
  padding: 0px 35px 25px 35px;

  .pairs-panel-title {
    padding: 10px 0 10px 0px;
    font-family: 'Montserrat';
    font-size: 30px;
    font-weight: 700;
    color: #0f2732;
  }

  .pairs-panel-list {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;

    @media screen and (max-width: 768px) {
      margin: 0px -5px;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #489989;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #489989;
    }
  }

  .pairs-panel-list table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    td:first-child {
      width: 100%;
    }

    thead td,
    tbody td {
      padding: 10px 70px 10px 10px;

      &:first-child {
        padding-left: 30px;
      }
    }

    tbody tr {
      &:first-child {
        td {
          border-top: 1px solid #d0f2eb;
        }

        td:first-child {
          border-radius: 10px 0px 0px 0px;
        }

        td:last-child {
          border-radius: 0px 10px 0px 0px;
        }
      }

      &:last-child {
        td:first-child {
          border-radius: 0px 0px 0px 10px;
        }

        td:last-child {
          border-radius: 0px 0px 10px 0px;
        }
      }

      td {
        background-color: #f1f9f8;
        border-bottom: 1px solid #d0f2eb;

        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        &:first-child {
          border-left: 1px solid #d0f2eb;
        }

        &:last-child {
          border-right: 1px solid #d0f2eb;
        }

        &.name {
          font-family: 'Montserrat';
          font-size: 18px;
          font-weight: 700;
          display: flex;
          align-items: center;

          img {
            margin-right: 30px;
          }
        }

        &.price {
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }
      }
    }

    thead {
      tr {
        td {
          white-space: nowrap;
          font-weight: bold;

          color: #a8adb6;
          width: 33.33333%;

          font-family: 'Montserrat';
          font-size: 10px;
          font-weight: 700;

          text-transform: uppercase;
        }
      }
    }

    @media screen and (max-width: 768px) {
      td:first-child {
        padding-left: 10px !important;
      }

      td {
        background: unset !important;
        border: unset !important;
      }

      td.name {
        width: unset;
        font-size: 14px !important;

        img {
          margin-right: 5px !important;
          width: 20px;
        }
      }

      td.price {
        width: 100% !important;
      }
    }
  }
}
</style>
