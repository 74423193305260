<template>
  <div class="wallet-panel">
    <div class="header-wrapper header-wrapper--desktop">
      <div class="header">
        <h2 class="title-h2">Deals</h2>
      </div>
      <div class="deals-header-block">
        <div class="deals-pagination ">
          <b-pagination
            v-if="allMarkers.length > perPage"
            v-model="currentPage"
            :total-rows="allMarkers.length"
            :per-page="perPage"
            aria-controls="paginated-table"
            hide-goto-end-buttons
            align="right"
            size="sm"
          ></b-pagination>
        </div>

        <button class="deals-export" v-if="percentDone">
          {{ percentDone }}%
        </button>
        <button class="deals-export" v-else @click="handleExportHistory">
          Export
        </button>
      </div>
    </div>
    <div class="header-wrapper header-wrapper--mobile">
      <div class="header">
        <h2 class="title-h2">Deals</h2>
      </div>
      <div class="deals-header-block">
        <button class="deals-export" v-if="percentDone">
          {{ percentDone }}%
        </button>
        <button class="deals-export" v-else @click="handleExportHistory">
          Export
        </button>
      </div>
    </div>

    <div class="body">
      <div class="wallet-information">
        <!--        <b-table class="table-wallet"
               id="paginated-table"
               :items="alltableMarkersData"
               :per-page="perPage"
               :current-page="currentPage"
               small>-->
        <table class="table-wallet">
          <thead>
            <tr>
              <!-- <td>Color</td> -->
              <td>Open time</td>
              <td>Type</td>
              <td>Size</td>
              <td>Pair name</td>
              <td>Open price</td>
              <td>Close time</td>
              <td>Close price</td>
              <td>Bot profit</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in tableMarkersData"
              :key="i"
              :class="{ 'buy-row': item.move > 0 }"
            >
              <td>
                {{ new Date(item.openTime).toJSON() | moment('DD-MM-yyyy') }}
                &nbsp;&nbsp;&nbsp;
                {{ new Date(item.openTime).toJSON() | moment('HH:mm') }}
              </td>
              <td>
                <div>
                  <ButtonRedGreen
                    :status="item.move > 0"
                    :content="item.move > 0 ? 'buy' : 'sell'"
                  />
                </div>
              </td>
              <td>
                {{ item.size | tradeCurrency }}
              </td>
              <td class="name">
                <cryptoicon
                  :symbol="item.pair | getCurrencyFromPair"
                  size="22"
                />

                <span style="margin-left: 10px;">
                  {{ item.pair | nameToPair }}
                </span>
              </td>
              <td>
                {{ item.priceOpen | currency }}
              </td>
              <td>
                {{
                  new Date(item.closeTime).toJSON() | moment('DD-MM-yyyy HH:mm')
                }}
              </td>
              <td>
                {{ item.priceClose | currency }}
              </td>
              <td>
                <div>
                  <ButtonRedGreen class="arrow" style="font-weight: 600;"
                  :status="item.profit > 0"
                  :content="item.profit | tradeCurrency"
                />
                </div>
                <!-- <div
                  class="profit"
                  :class="{ green: item.profit > 0, red: item.profit < 0 }"
                >
                  ${{ item.profit | tradeCurrency }}
                </div> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="deals-pagination deals-pagination--bottom ">
      <b-pagination
        v-if="allMarkers.length > perPage"
        v-model="currentPage"
        :total-rows="allMarkers.length"
        :per-page="perPage"
        aria-controls="paginated-table"
        hide-goto-end-buttons
        align="right"
        size="sm"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
function paginate(array, page_size, page_number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size)
}

import { mapActions, mapGetters } from 'vuex'
import ButtonRedGreen from '../../components/ButtonRedGreen'
import { DateTime } from 'luxon'

import ExportHistoryMixin from './export-history.mixin'

export default {
  mixins: [ExportHistoryMixin],

  components: {
    ButtonRedGreen,
  },
  name: 'Deals',
  data: () => ({
    currentPage: 1,
    perPage: 12,
  }),
  computed: {
    ...mapGetters('Finance', [
      'getterPairsMarkersByLimit',
      'getterFuturesPrices',
    ]),

    tableMarkersData() {
      return paginate(this.allMarkers, this.perPage, this.currentPage)
    },

    allMarkers() {
      return this.getterPairsMarkersByLimit(200).reverse()
    },
  },

  methods: {
    ...mapActions('Finance', ['getDealsWithPagination']),

    async onExport() {
      const [startAt, endAt] = this.formDataExport || []

      const period =
        startAt && endAt
          ? {
              startAt: DateTime.fromMillis(new Date(startAt).valueOf())
                .startOf('day')
                .valueOf(),

              endAt: DateTime.fromMillis(new Date(endAt).valueOf())
                .endOf('day')
                .valueOf(),
            }
          : undefined

      const PER_Q_LIMIT = 1000

      // get recursive data
      let getData = async (prevDocs = [], page = 1) => {
        let {
          docs,
          totalDocs,
          nextPage,
          hasNextPage,
        } = await this.getDealsWithPagination({
          pagination: {
            page,
            limit: PER_Q_LIMIT,
            sort: { closeTime: -1 },
          },
          period,
        })

        this.progressExport.total = totalDocs
        this.progressExport.progress = prevDocs.length + docs.length

        if (hasNextPage) {
          return await getData([...prevDocs, ...docs], nextPage)
        }

        return [...prevDocs, ...docs]
      }

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Transactions')

      worksheet.columns = [
        {
          header: 'OPEN TIME',
          key: 'openTime',
          width: 18,
          options: { dateFormat: 'DD-MM-YYYY HH:mm' },
        },
        {
          header: 'TYPE',
          key: 'type',
          width: 8,
        },
        {
          header: 'SIZE',
          key: 'size',
          width: 20,
        },
        {
          header: 'PAIR NAME',
          key: 'pair',
          width: 10,
        },
        {
          header: 'OPEN PRICE',
          key: 'openPrice',
          width: 15,
        },
        {
          header: 'CLOSE TIME',
          key: 'closeTime',
          width: 18,
          options: { dateFormat: 'DD-MM-YYYY HH:mm' },
        },
        {
          header: 'CLOSE PRICE',
          key: 'priceClose',
          width: 15,
        },
        {
          header: 'PROFIT',
          key: 'profit',
          width: 15,
        },
      ]

      const formatDateTime = timestamp =>
        this.$luxon(new Date(timestamp).toJSON(), 'dd-MM-yyyy HH:mm')

      const dd = await getData()

      for (let i = 0; i < dd.length; i++) {
        worksheet.addRow({
          openTime: formatDateTime(dd[i].openTime),
          type: dd[i].move > 0 ? 'BUY' : 'SELL',
          size: dd[i].size,
          pair: dd[i].pair.replace('USDT', '/USDT'),
          openPrice: dd[i].priceOpen,
          closeTime: formatDateTime(dd[i].closeTime),
          priceClose: dd[i].priceClose,
          profit: dd[i].profit,
        })
      }

      workbook.xlsx
        .writeBuffer()
        .then(buffer => {
          downloadFileByBlob(
            buffer,
            `${
              process.env.VUE_APP_SITE_NAME
            }_transactions_${DateTime.now().toFormat('dd-MM-yyyy__HH:mm')}.xlsx`
          )

          this.$nextTick(() => {
            this.progressExport.progress = 0
          })
        })
        .catch(error => {
          throw error
        })
    },
  },
}
</script>

<style scoped></style>
