var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pairs-panel"
  }, [_c('div', {
    staticClass: "pairs-panel-title"
  }, [_vm._v(" Pairs ")]), _c('div', {
    staticClass: "pairs-panel-list"
  }, [_c('table', [_vm._m(0), _c('tbody', _vm._l(_vm.zones, function (item, i) {
    return _c('tr', {
      key: i,
      class: {
        tradable: item.tradable,
        active: item.pair == _vm.activePair
      },
      on: {
        "click": function ($event) {
          return _vm.handleClickOnPair(item, i);
        }
      }
    }, [_c('td', {
      staticClass: "name"
    }, [_c('cryptoicon', {
      attrs: {
        "symbol": _vm._f("getCurrencyFromPair")(item.pair),
        "size": "32"
      }
    }), _vm._v(" " + _vm._s(item.pair) + " ")], 1), _c('td', {
      staticClass: "price"
    }, [_vm._v("$" + _vm._s(_vm._f("tradeCurrency")(item.price)))])]);
  }), 0)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('td', [_vm._v("Name")]), _c('td', [_vm._v("Price, $")])])]);

}]

export { render, staticRenderFns }