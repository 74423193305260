<template>
  <div>
    <apexchart
      type="line"
      height="332px"
      ref="chart"
      :options="chartData.options"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapActions, mapState } from 'vuex'
import { DateTime } from 'luxon'

export default {
  data: () => ({
    daysBalances: [],
  }),

  components: {
    apexchart: VueApexCharts,
  },

  methods: {
    ...mapActions('Finance', ['getAppBalanceWithPagination']),
  },

  created() {
    this.getAppBalanceWithPagination({ limit: 5, sort: { date: -1 } }).then(
      data => {
        this.daysBalances = data.docs
        console.log(data.docs)
      }
    )
  },

  computed: {
    ...mapState('Finance', ['balanceStats']),

    series() {
      if (!this.daysBalances.length) return []

      return [
        {
          name: 'balance',
          data: this.daysBalances.map(e => e.balance.toFixed(2)),
        },
      ]
    },

    chartData() {
      return {
        options: {
          type: 'line',
          colors: ['#20B48C'],
          grid: {
            show: true,
            borderColor: '#ECECEC',
          },
          chart: {
            id: 'apexchart-indicators',
            foreColor: '#9f9fa1',
            animations: {
              enabled: true,
              easing: 'linear',
              dynamicAnimation: {
                speed: 1000,
              },
            },

            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          stroke: {
            curve: 'smooth',
            show: true,
            lineCap: 'butt',
            colors: undefined,
            width: 3,
            dashArray: 0,
          },
          legend: {
            show: false,
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: val => this.$options.filters.currency(val),
            },
          },

          yaxis: {
            show: false,

            labels: {
              formatter: val => this.$options.filters.currency(val),
            },
          },

          xaxis: {
            labels: {
              show: false,
              rotate: -20,
            },

            categories: this.daysBalances
              .reverse()
              .map(e => DateTime.fromISO(e.date).toFormat('dd.MM.yyyy')),

            axisBorder: {
              show: false,
            },

            axisTicks: {
              show: false,
            },
          },
        },
        series: this.series,
      }
    },
  },
}
</script>
