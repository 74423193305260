var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bot-terminal-panel"
  }, [_vm._m(0), _c('div', {
    staticClass: "body"
  }, [_c('div', {
    ref: "commandsRef",
    staticClass: "commands"
  }, _vm._l(_vm.sortedListMessages, function (item, i) {
    var _vm$$t;
    return _c('div', {
      key: i,
      staticClass: "item"
    }, [_c('div', {
      staticClass: "time d-flex no-wrap"
    }, [_vm._v(" " + _vm._s(_vm._f("moment")(item.createdAt, 'DD-MM-yyyy')) + " "), _c('div', {
      staticStyle: {
        "width": "15px"
      }
    }), _vm._v(" " + _vm._s(_vm._f("moment")(item.createdAt, 'HH:mm')) + " ")]), _c('div', {
      staticClass: "details"
    }, [_vm._v(" " + _vm._s((_vm$$t = _vm.$t(item.langKey, _vm.getArgs(item.langArgs))) === null || _vm$$t === void 0 ? void 0 : _vm$$t.replace('Sell', '').replace('Buy', '')) + " "), item.mark ? _c('ButtonRedGreen', {
      staticStyle: {
        "margin-left": "15px"
      },
      attrs: {
        "status": item.mark === 'buy',
        "content": item.mark
      }
    }) : _vm._e()], 1)]);
  }), 0)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header-box"
  }, [_c('div', {
    staticClass: "title-h2"
  }, [_vm._v("Trading Statistics Log")])])]);

}]

export { render, staticRenderFns }