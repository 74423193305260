var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "balance-history-panel"
  }, [_vm._m(0), _c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "tabs-history"
  }, _vm._l(_vm.tabs, function (item, key) {
    return _c('div', {
      key: key,
      staticClass: "item",
      class: {
        active: key == _vm.activeTab
      },
      on: {
        "click": function ($event) {
          _vm.activeTab = key;
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0), _vm.activeTab == _vm.tabs.statistic ? _c('table', {
    staticClass: "balance-history-table"
  }, [_vm._m(1), _c('tbody', _vm._l(_vm.list, function (item, i) {
    return _c('tr', {
      key: i
    }, [_c('td', {
      staticClass: "balance-history-table__heading"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('td', [_c('ButtonRedGreen', {
      attrs: {
        "status": item.percent > 0,
        "content": item.percent + '%'
      }
    })], 1), _c('td', [_c('div', {
      staticClass: "balance"
    }, [_vm._v(" " + _vm._s(_vm._f("currency")(item.balance)) + " ")])]), _c('td', [_c('div', {
      staticClass: "balance"
    }, [_vm._v(" " + _vm._s(item.percent ? '+' : '-') + _vm._s(_vm._f("currency")(item.balance * item.percent / 100)) + " ")])])]);
  }), 0)]) : _vm._e(), _vm.activeTab == _vm.tabs.history ? _c('div', {
    staticClass: "tab-history"
  }, [_c('table', [_vm._m(2), _c('tbody', _vm._l(_vm.listBalancesComputed, function (item, i) {
    return _c('tr', {
      key: i
    }, [_c('td', [_vm._v(_vm._s(_vm._f("currency")(item.balance)))]), _c('td', [_vm._v(_vm._s(_vm._f("luxon")(item.date, 'd-MM-yyyy')))])]);
  }), 0)])]) : _vm._e()])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('h2', {
    staticClass: "title-h2"
  }, [_vm._v("Trading Balance")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('td'), _c('td', [_vm._v("Change")]), _c('td', [_vm._v("Balance")]), _c('td')])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('td', [_vm._v("Balance")]), _c('td', [_vm._v("Date")])])]);

}]

export { render, staticRenderFns }