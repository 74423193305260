<template>
  <div class="trading-balance-panel">
    <div class="header">
      <div class="wallet-box">
        <div class="title">
          <h2 class="title-h2">Amount of capital</h2>
        </div>
      </div>

      <BalanceGraphic />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BalanceGraphic from './BalanceGraphic.vue'

export default {
  components: {
    BalanceGraphic,
  },

  filters: {
    nameToPair: (val, currencies) => {
      return currencies[val]?.pair || name
    },
  },

  computed: {
    ...mapGetters('Finance', ['getterFuturesPrices']),
    ...mapGetters('Finance', ['getterPairsMarkersByLimit']),

    tableMarkersData() {
      return this.getterPairsMarkersByLimit(5).reverse()
    },
  },
}
</script>

<style lang="scss">
.trading-balance-panel {
  padding: 0px 35px 0px 35px;
  margin-bottom: 30px;

  .header {
    .wallet-box {
      .title {
        .title-h2 {
          color: #ffffff;
          padding: 10px 0 30px;

          font-family: 'Montserrat';
          font-size: 30px;
          font-weight: 700;
          color: #0f2732;
        }
      }
    }
  }
}
</style>
