<template>
  <span
    :class="[
      status === false ? 'red' : 'green',
      content.endsWith('%') === true ? 'arrow' : '',
    ]"
  >
    {{ content }}
  </span>
</template>

<script>
export default {
  name: 'ButtonRedGreen',
  props: {
    content: String,
    status: Boolean,
  },
}
</script>

<style scoped lang="scss">
.green,
.red {
  border-radius: 60px;
  width: fit-content;
  text-transform: capitalize;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 700;
}
.green {
  color: #65d072;

  &.arrow {
    &:before {
      content: url('/img/arrow-up.svg');
      position: relative;
      margin-right: 10px;
      top: 1px;
    }
  }
}
.red {
  color: #d32d2f;

  &.arrow {
    &:before {
      content: url('/img/arrow-down.svg');
      position: relative;
      margin-right: 10px;
      top: 1px;
    }
  }
}
</style>
