import ExcelJS from 'exceljs'

import { downloadFileByBlob } from '../../utils'

import { mapActions, mapState } from 'vuex'

import { DateTime } from 'luxon'

const PER_Q_LIMIT = 1000

export default {
  data: () => ({
    formDataExport: undefined,
    progressExport: {
      total: 100,
      progress: 0,
    },
  }),

  computed: {
    percentDone() {
      if (!this.progressExport.progress) return 0

      return (
        (this.progressExport.progress / this.progressExport.total) *
        100
      ).toFixed()
    },

    ...mapState('Finance', ['selectedPairName']),
  },

  methods: {
    // ...mapActions('Deals', ['getDealsWithPagination']),
    ...mapActions('Finance', ['getDealsWithPagination', 'getLastDealsHistory']),

    async handleExportHistory() {
      const [startAt, endAt] = this.formDataExport || []

      const interval = setInterval(() => {
        if (this.progressExport.progress < 99) this.progressExport.progress++
      }, 200)

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Transactions')

      worksheet.columns = [
        {
          header: 'OPEN TIME',
          key: 'openTime',
          width: 18,
          options: { dateFormat: 'DD-MM-YYYY HH:mm' },
        },
        {
          header: 'TYPE',
          key: 'type',
          width: 8,
        },
        {
          header: 'SIZE',
          key: 'size',
          width: 20,
        },
        {
          header: 'PAIR NAME',
          key: 'pair',
          width: 10,
        },
        {
          header: 'OPEN PRICE',
          key: 'openPrice',
          width: 15,
        },
        {
          header: 'CLOSE TIME',
          key: 'closeTime',
          width: 18,
          options: { dateFormat: 'DD-MM-YYYY HH:mm' },
        },
        {
          header: 'CLOSE PRICE',
          key: 'priceClose',
          width: 15,
        },
        {
          header: 'PROFIT',
          key: 'profit',
          width: 15,
        },
      ]

      const formatDateTime = timestamp =>
        this.$luxon(new Date(timestamp).toJSON(), 'dd-MM-yyyy HH:mm')

      // TABLE_TEADER
      this.getLastDealsHistory({
        pair: this.selectedPairName,
        dateStart: Date.now(),
        dateEnd: new DateTime(Date.now()).minus({ months: 2 }).valueOf(),
        limit: 30000,
      })
        .then(data => {
          this.progressExport.progress = 99

          worksheet.addRows(
            data.map(e => ({
              id: this.$options.filters.mongoIdToNumber(e._id),
              openTime: formatDateTime(e.openTime),
              type: e.move > 0 ? 'BUY' : 'SELL',
              size: e.size,
              pair: e.pair.replace('USDT', '/USDT'),
              openPrice: e.priceOpen,
              closeTime: formatDateTime(e.closeTime),
              priceClose: e.priceClose,
              profit: e.profit,
            }))
          )

          workbook.xlsx
            .writeBuffer()
            .then(buffer => {
              downloadFileByBlob(
                buffer,
                `transactions_${DateTime.now().toFormat(
                  'dd-MM-yyyy__HH:mm'
                )}.xlsx`
              )

              this.progressExport.progress = 100
            })
            .catch(error => {
              throw error
            })
            .finally(() => {
              setTimeout(() => {
                this.progressExport.progress = 0
              }, 1000)
            })
        })
        .finally(() => {
          clearInterval(interval)
        })
    },
  },
}
